import React, { useEffect, useRef } from "react";
import "./Modal.css";

const Modal = ({ isVisible, hideModal, children, titulo }) => {
  const backdropRef = useRef(null);
  const dialogRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        if (backdropRef.current && dialogRef.current) {
          backdropRef.current.classList.add("show");
          dialogRef.current.classList.add("show");
        }
      }, 10); // Slight delay to trigger CSS transition
    } else {
      if (backdropRef.current && dialogRef.current) {
        backdropRef.current.classList.remove("show");
        dialogRef.current.classList.remove("show");
      }
      document.body.style.overflow = "auto";
    }
  }, [isVisible]);

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      hideModal();
    }
  };

  return (
    <div
      ref={backdropRef}
      className={`modal-backdrop`}
      onClick={handleBackdropClick}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <div ref={dialogRef} className={`modal-dialog`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{titulo}</h5>
            <button type="button" className="close" onClick={hideModal}>
              &times;
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
