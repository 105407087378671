import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faHome,
  faList,
  faCity,
  faUsers,
  faSignOutAlt,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import "./Sidebar.css";

function Sidebar() {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button className="btn toggle-button" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div
        className={`sidebar ${
          isOpen ? "open" : "closed"
        } d-flex flex-column p-3`}
      >
        <div className="text-center mb-4">
          <img style={{ width: "100px" }} alt="Logo" src="/logo-not.png" />
        </div>
        <h2 className="text-center text-white">
          {isOpen ? "Administrador" : "Adm"}
        </h2>
        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location.pathname === "/dashboard" ? "active" : ""
              }`}
              to="/dashboard"
            >
              <FontAwesomeIcon icon={faTh} className="me-2" />{" "}
              {isOpen && "Dashboard"}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location.pathname === "/" ? "active" : ""
              }`}
              to="/"
            >
              <FontAwesomeIcon icon={faHome} className="me-2" />{" "}
              {isOpen && "Listar Propriedades"}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location.pathname === "/categorias" ? "active" : ""
              }`}
              to="/categorias"
            >
              <FontAwesomeIcon icon={faList} className="me-2" />{" "}
              {isOpen && "Listar Categorias"}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location.pathname === "/cidades" ? "active" : ""
              }`}
              to="/cidades"
            >
              <FontAwesomeIcon icon={faCity} className="me-2" />{" "}
              {isOpen && "Listar Cidades"}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                location.pathname === "/usuarios" ? "active" : ""
              }`}
              to="/usuarios"
            >
              <FontAwesomeIcon icon={faUsers} className="me-2" />{" "}
              {isOpen && "Listar Usuários"}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/login"
              onClick={() => {
                sessionStorage.clear();
                window.location.href = "/login";
                window.location.reload();
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
              {isOpen && "Sair"}
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
