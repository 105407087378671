import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
  faStar,
  faBan,
  faDownload,
  faCheckCircle,
  faTimesCircle,
  faCube,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { server } from "../../util/server";
import { toast } from "react-toastify";
import axiosInstance from "../../source/axiosInstance";

import "./PropertyList.css";

// Mapeando os ids dos estados e alterando para o nome do estado na tabela
const estados = {
  "V1-B-241": "AC",
  "V1-B-242": "AL",
  "V1-B-243": "AP",
  "V1-B-244": "AM",
  "V1-B-245": "BA",
  "V1-B-246": "CE",
  "V1-B-247": "DF",
  "V1-B-248": "ES",
  "V1-B-249": "GO",
  "V1-B-250": "MA",
  "V1-B-251": "MT",
  "V1-B-252": "MS",
  "V1-B-253": "MG",
  "V1-B-256": "PR",
  "V1-B-255": "PB",
  "V1-B-254": "PA",
  "V1-B-257": "PE",
  "V1-B-258": "PI",
  "V1-B-259": "RJ",
  "V1-B-260": "RN",
  "V1-B-261": "RS",
  "V1-B-262": "RO",
  "V1-B-263": "RR",
  "V1-B-264": "SC",
  "V1-B-266": "SE",
  "V1-B-265": "SP",
  "V1-B-267": "TO",
  "V1-B-4000": "Outros Países",
};

function PropertyList({
  properties,
  currentPage,
  totalPages,
  onPageChange,
  onEdit,
  toggleStatus,
  toggleHighlight,
  toggleHighlightImovelWeb,
  downloadImages,
  handleDestaqueChange,
}) {
  const navigate = useNavigate();

  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = 4;

    const addPageButton = (page) => {
      pages.push(
        <li
          key={page}
          className={`page-item ${page === currentPage ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => onPageChange(page)}>
            {page}
          </button>
        </li>
      );
    };

    addPageButton(1);

    if (currentPage > maxPagesToShow) {
      pages.push(
        <li key="start-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    const startPage = Math.max(2, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(
      totalPages - 1,
      currentPage + Math.floor(maxPagesToShow / 2)
    );

    for (let page = startPage; page <= endPage; page++) {
      addPageButton(page);
    }

    if (currentPage < totalPages - maxPagesToShow / 2) {
      pages.push(
        <li key="end-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    if (totalPages > 1) {
      addPageButton(totalPages);
    }

    return (
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage - 1)}
          >
            Anterior
          </button>
        </li>
        {pages}
        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage + 1)}
          >
            Próximo
          </button>
        </li>
      </ul>
    );
  };

  const handleImageUploadClick = (id) => {
    navigate(`/upload-images/${id}`);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  return (
    <div className="property-list">
      <table className="table table-hover table-striped">
        <thead className="table-dark">
          <tr>
            <th>Referência</th>
            <th>Categorias</th>
            <th>Localização</th>
            <th>Valor</th>
            <th>ImovelWeb</th>
            <th>Destacar</th>
            <th>Status</th>
            <th>Integração</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {properties.map((property) => (
            <tr key={property._id}>
              <td>{property.code}</td>
              <td>{property.category}</td>
              <td>{property.location}</td>
              <td>{property.price}</td>

              <td className="icon-align">
                {property.highlightImovelWeb == "A" ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-danger"
                  />
                )}
              </td>

              <td className="icon-align">
                {property.highlight ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-danger"
                  />
                )}
              </td>
              <td className="icon-align">
                {property.status === "A" ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-danger"
                  />
                )}
              </td>
              <td>
                <select
                  className="form-control"
                  value={property.destaque}
                  onChange={(e) =>
                    handleDestaqueChange(property, e.target.value)
                  }
                  disabled={
                    property.highlightImovelWeb == "I" ||
                    property.highlightImovelWeb == ""
                  }
                >
                  {property.toggleHighlightImovelWeb == "I" ? (
                    <option>Ative a Integração</option>
                  ) : (
                    <></>
                  )}
                  <option value="SIMPLE">Simples</option>
                  <option value="DESTACADO">Destaque</option>
                  <option value="HOME">Super Destaque</option>
                </select>
              </td>
              <td>
                <button
                  className="btn btn-warning btn-sm me-2"
                  onClick={() => onEdit(property)}
                  title="Editar Propriedade"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  title="Adicionar Imagens"
                  className="btn btn-primary btn-sm me-2"
                  onClick={() => handleImageUploadClick(property._id)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>

                <button
                  title={
                    property.highlightImovelWeb == "A"
                      ? "Remover ImovelWeb"
                      : "Destacar ImovelWeb"
                  }
                  className={`btn btn-sm me-2 ${
                    property.highlightImovelWeb == "A"
                      ? "btn-danger"
                      : "btn-info"
                  }`}
                  onClick={() => toggleHighlightImovelWeb(property)}
                >
                  <FontAwesomeIcon icon={faCube} />
                </button>

                <button
                  title={property.highlight ? "Remover Destaque" : "Destacar"}
                  className={`btn btn-sm me-2 ${
                    property.highlight ? "btn-danger" : "btn-success"
                  }`}
                  onClick={() => toggleHighlight(property)}
                >
                  <FontAwesomeIcon icon={faStar} />
                </button>

                <button
                  title={property.status === "A" ? "Desativar" : "Ativar"}
                  className={`btn btn-sm me-2 ${
                    property.status === "A" ? "btn-secondary" : "btn-success"
                  }`}
                  onClick={() => toggleStatus(property)}
                >
                  <FontAwesomeIcon icon={faBan} />
                </button>
                <button
                  title="Baixar imagens"
                  className="btn btn-dark btn-sm"
                  onClick={() => downloadImages(property)}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {renderPagination()}
    </div>
  );
}

export default PropertyList;
