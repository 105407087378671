import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCheckCircle,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { createUsuario, fetchUsuarios, updateUsuario } from "../../api/user";
import { useModal } from "../../hooks/useModal";
import axiosInstance from "../../source/axiosInstance";
import { server } from "../../util/server";
import Modal from "../../components/Modal/Modal";
import "./ManagerUsuarios.css";

function ManagerUsuarios() {
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
  const [editUsuario, setEditUsuario] = useState(null);
  const [newUsuario, setNewUsuario] = useState({
    nome: "",
    username: "",
    password: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [needsUpdate, setNeedsUpdate] = useState(false);

  const modalCreate = useModal();
  const modalEdit = useModal();

  useEffect(() => {
    fetchUsers();
  }, [currentPage, itemsPerPage, search, needsUpdate]);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get(`${server}/api/user/paginated`, {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: search,
        },
      });
      setUsuarios(response.data.users);
      setUsuariosFiltrados(response.data.users);
      setTotalPages(response.data.totalPages);
      setNeedsUpdate(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEditClick = (usuario) => {
    setEditUsuario(usuario);
    modalEdit.showModal();
  };

  const handleUpdateUsuario = async (usuario) => {
    try {
      await updateUsuario(usuario._id, usuario);
      modalEdit.hideModal();
      setEditUsuario(null);
      toast.success("Usuário atualizado com sucesso!");
      setNeedsUpdate(true);
    } catch (error) {
      console.error("Erro ao atualizar o usuário:", error);
      toast.error("Erro ao atualizar o usuário");
    }
  };

  const handleCreateUsuario = async (usuario) => {
    try {
      await createUsuario(usuario);
      modalCreate.hideModal();
      setNewUsuario({ nome: "", username: "", password: "" });
      toast.success("Usuário criado com sucesso!");
      setNeedsUpdate(true);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        console.error("Erro ao criar o usuário:", error);
        toast.error("Erro ao criar o usuário");
      }
    }
  };

  const toggleStatus = async (user) => {
    try {
      toast.info("Aguarde um pouco...");
      const response = await axiosInstance.put(
        `${server}/api/user/${user._id}/toggle-status`
      );
      user.status = response.data.status;
      setNeedsUpdate(true);
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = 4;

    const addPageButton = (page) => {
      pages.push(
        <li
          key={page}
          className={`page-item ${page === currentPage ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageChange(page)}>
            {page}
          </button>
        </li>
      );
    };

    addPageButton(1);

    if (currentPage > maxPagesToShow) {
      pages.push(
        <li key="start-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    const startPage = Math.max(2, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(
      totalPages - 1,
      currentPage + Math.floor(maxPagesToShow / 2)
    );

    for (let page = startPage; page <= endPage; page++) {
      addPageButton(page);
    }

    if (currentPage < totalPages - maxPagesToShow / 2) {
      pages.push(
        <li key="end-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    if (totalPages > 1) {
      addPageButton(totalPages);
    }

    return (
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Anterior
          </button>
        </li>
        {pages}
        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Próximo
          </button>
        </li>
      </ul>
    );
  };

  return (
    <>
      <div className="container mt-5">
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="title-container"
        >
          <h2>Lista de Usuários</h2>
          <button className="btn btn-success" onClick={modalCreate.showModal}>
            Criar Usuário
          </button>
        </div>
        <br />
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Buscar usuários..."
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <select
            className="form-control"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Usuário</th>
                <th>Status</th>
                <th>Opções</th>
              </tr>
            </thead>
            <tbody>
              {usuariosFiltrados
                .slice()
                .sort((a, b) => a.username.localeCompare(b.username))
                .map((usuario) => (
                  <tr key={usuario._id}>
                    <td>{usuario.nome}</td>
                    <td>{usuario.username}</td>
                    <td>
                      {usuario.status === "A" ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="text-danger"
                        />
                      )}
                    </td>
                    <td>
                      <button
                        className="btn btn-warning btn-sm mr-1"
                        onClick={() => handleEditClick(usuario)}
                        title="Editar Usuário"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button
                        title={usuario.status === "A" ? "Desativar" : "Ativar"}
                        className={`btn btn-sm mr-1 ${
                          usuario.status === "A"
                            ? "btn-secondary"
                            : "btn-success"
                        }`}
                        onClick={() => toggleStatus(usuario)}
                      >
                        <FontAwesomeIcon icon={faBan} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {renderPagination()}
      </div>

      <Modal
        hideModal={modalCreate.hideModal}
        isVisible={modalCreate.isVisible}
        titulo={"Criar Usuário"}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateUsuario(newUsuario);
          }}
        >
          <div className="form-group">
            <label>Nome:</label>
            <input
              type="text"
              className="form-control"
              value={newUsuario.nome}
              onChange={(e) =>
                setNewUsuario({ ...newUsuario, nome: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Usuário:</label>
            <input
              type="text"
              className="form-control"
              value={newUsuario.username}
              onChange={(e) =>
                setNewUsuario({ ...newUsuario, username: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Senha:</label>
            <input
              type="password"
              className="form-control"
              value={newUsuario.password}
              onChange={(e) =>
                setNewUsuario({ ...newUsuario, password: e.target.value })
              }
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Criar
          </button>
        </form>
      </Modal>

      <Modal
        hideModal={() => {
          modalEdit.hideModal();
          setNeedsUpdate(true);
        }}
        isVisible={modalEdit.isVisible}
        titulo={"Editar Usuário"}
      >
        {editUsuario && (
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateUsuario(editUsuario);
              }}
            >
              <div className="form-group">
                <label>Nome:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editUsuario.nome}
                  onChange={(e) =>
                    setEditUsuario({ ...editUsuario, nome: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label>Usuário:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editUsuario.username}
                  onChange={(e) =>
                    setEditUsuario({
                      ...editUsuario,
                      username: e.target.value,
                    })
                  }
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Atualizar
              </button>
            </form>
          </div>
        )}
      </Modal>
    </>
  );
}

export default ManagerUsuarios;
