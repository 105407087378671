import axiosInstance from "../source/axiosInstance";

export const fetchUsuarios = async () => {
  const response = await axiosInstance.get(`api/user/`);
  return response.data.usuarios;
};

export const fetchByUsuarioId = async (usuario) => {
  const response = await axiosInstance.get(`api/user/${usuario._id}`);
  return response.data.usuario;
};

export const createUsuario = async (usuario) => {
  await axiosInstance.post(`api/user/register`, usuario);
};

export const updateUsuario = async (id, usuario) => {
  await axiosInstance.put(`api/user/${id}`, usuario);
};

export const deleteUsuario = async (usuario) => {
  await axiosInstance.delete(`api/user/${usuario._id}`);
};
