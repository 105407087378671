import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../util/server";

const axiosInstance = axios.create({
  baseURL: server,
});

let isNotificationShown = false;

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    const imovelWebToken = sessionStorage.getItem("imovelWebToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (imovelWebToken) {
      config.headers["imovelweb-token"] = `${imovelWebToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      (error.response && (error.response.status === 403 || error.response.status === 401))
    ) {
      if (!isNotificationShown) {
        isNotificationShown = true;
        window.dispatchEvent(new CustomEvent("unauthorized"));
        toast.error("Acesso negado. Redirecionando para login...");

        // Reseta o estado depois de um tempo para permitir futuras notificações
        setTimeout(() => {
          isNotificationShown = false;
        }, 5000); // 5 segundos, ajuste conforme necessário
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;