import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./PropertyForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../util/server";
import axiosInstance from "../../source/axiosInstance";

Modal.setAppElement("#root");

function PropertyForm({ property = null, setEditProperty = () => {} }) {
  const [title, setTitle] = useState(property ? property.title : "");
  const [description, setDescription] = useState(
    property ? property.description : ""
  );
  const [tipoPropriedade, setTipoPropriedade] = useState(
    property ? property.tipoPropriedade : ""
  ); //Adicionado
  const [category, setCategory] = useState(property ? property.category : "");
  const [code, setCode] = useState(property ? property.code : "");
  const [location, setLocation] = useState(property ? property.location : "");
  const [price, setPrice] = useState(property ? property.price : "");
  const [details, setDetails] = useState(
    property ? property.details.join(",") : ""
  );
  const [fullDescription, setFullDescription] = useState(
    property ? property.full_description : ""
  );
  const [reference, setReference] = useState(
    property ? property.reference : ""
  );
  const [state, setState] = useState(property ? property.estadoId : "");
  const [city, setCity] = useState(property ? property.cidadeId : "");
  const [neighborhood, setNeighborhood] = useState(
    property ? property.bairroId : ""
  );
  const [dorm, setDorm] = useState(property ? property.dorm || 0 : 0);
  const [suite, setSuite] = useState(property ? property.suite || 0 : 0);
  const [suiteMaster, setSuiteMaster] = useState(
    property ? property.suite_master || false : false
  );
  const [salaoFestas, setSalaoFestas] = useState(
    property ? property.salao_festas || false : false
  );
  const [churrasqueira, setChurrasqueira] = useState(
    property ? property.churrasqueira || false : false
  );
  const [quiosque, setQuiosque] = useState(
    property ? property.quiosque || false : false
  );
  const [salaJantar, setSalaJantar] = useState(
    property ? property.sala_jantar || false : false
  );
  const [piscina, setPiscina] = useState(
    property ? property.piscina || false : false
  );
  const [salaTV, setSalaTV] = useState(
    property ? property.sala_tv || false : false
  );
  const [sauna, setSauna] = useState(
    property ? property.sauna || false : false
  );
  const [salaEstar, setSalaEstar] = useState(
    property ? property.sala_estar || false : false
  );
  const [campoFutebol, setCampoFutebol] = useState(
    property ? property.campo_futebol || false : false
  );
  const [lareira, setLareira] = useState(
    property ? property.lareira || false : false
  );
  const [quadraEsportes, setQuadraEsportes] = useState(
    property ? property.quadra_esportes || false : false
  );
  const [mezanino, setMezanino] = useState(
    property ? property.mezanino || false : false
  );
  const [quadraTenis, setQuadraTenis] = useState(
    property ? property.quadra_tenis || false : false
  );
  const [peDireitoAlto, setPeDireitoAlto] = useState(
    property ? property.pe_direito_alto || false : false
  );
  const [casaCaseiro, setCasaCaseiro] = useState(
    property ? property.casa_caseiro || false : false
  );
  const [cozinha, setCozinha] = useState(
    property ? property.cozinha || false : false
  );
  const [casaHospedes, setCasaHospedes] = useState(
    property ? property.casa_hospedes || false : false
  );
  const [copa, setCopa] = useState(property ? property.copa || false : false);
  const [vagas, setVagas] = useState(property ? property.vagas || "" : "");
  const [despensa, setDespensa] = useState(
    property ? property.despensa || false : false
  );
  const [canil, setCanil] = useState(
    property ? property.canil || false : false
  );
  const [banheiros, setBanheiros] = useState(
    property ? property.banheiros || 0 : 0
  );
  const [lavabo, setLavabo] = useState(
    property ? property.lavabo || false : false
  );
  const [lago, setLago] = useState(property ? property.lago || false : false);
  const [areaServicos, setAreaServicos] = useState(
    property ? property.area_servicos || false : false
  );
  const [nascente, setNascente] = useState(
    property ? property.nascente || false : false
  );
  const [varanda, setVaranda] = useState(
    property ? property.varanda || false : false
  );
  const [pomar, setPomar] = useState(
    property ? property.pomar || false : false
  );
  const [quartinhoFerramentas, setQuartinhoFerramentas] = useState(
    property ? property.quartinho_ferramentas || false : false
  );
  const [horta, setHorta] = useState(
    property ? property.horta || false : false
  );
  const [salaJogos, setSalaJogos] = useState(
    property ? property.sala_jogos || false : false
  );
  const [jardim, setJardim] = useState(
    property ? property.jardim || false : false
  );
  const [baia, setBaia] = useState(property ? property.baia || false : false);
  const [ac, setAc] = useState(property ? property.ac || "" : ""); // Área construída
  const [at, setAt] = useState(property ? property.at || "" : ""); // Área total
  const [owner, setOwner] = useState(property ? property.owner || "" : ""); // Proprietário
  const [caretaker, setCaretaker] = useState(
    property ? property.caretaker || "" : ""
  ); // Caseiro
  const [dormSuite, setDormSuite] = useState(
    property ? property.dorm_suite || "" : ""
  );
  const [dataConstrucao, setDataConstrucao] = useState(
    property ? property.data_construcao || "" : ""
  );
  const [iptu, setIptu] = useState(property ? property.iptu || "" : "");
  const [condominio, setCondominio] = useState(
    property ? property.condominio || "" : ""
  );
  const [video, setVideo] = useState(property ? property.video || "" : "");
  const [valorVenda, setValorVenda] = useState(
    property ? property.valor_venda || "" : ""
  );
  const [email, setEmail] = useState(property ? property.email || "" : "");
  const [tel1, setTel1] = useState(property ? property.tel1 || "" : "");
  const [tel2, setTel2] = useState(property ? property.tel2 || "" : "");
  const [tel3, setTel3] = useState(property ? property.tel3 || "" : "");
  const [telC, setTelC] = useState(property ? property.tel_c || "" : "");
  const [admImovel, setAdmImovel] = useState(
    property ? property.adm_imovel || "" : ""
  );
  const [chaves, setChaves] = useState(property ? property.chaves || "" : "");
  const [captacao, setCaptacao] = useState(
    property ? property.captacao || "" : ""
  );
  const [captacaoNome, setCaptacaoNome] = useState(
    property ? property.captacao_nome || "" : ""
  );
  const [captacaoEmail, setCaptacaoEmail] = useState(
    property ? property.captacao_email || "" : ""
  );
  const [captacaoTelefone, setCaptacaoTelefone] = useState(
    property ? property.captacao_telefone || "" : ""
  );
  const [docs, setDocs] = useState(property ? property.docs || "" : "");
  const [comissao, setComissao] = useState(
    property ? property.comissao || "" : ""
  );
  const [comissaoLoc, setComissaoLoc] = useState(
    property ? property.comissao_loc || "" : ""
  );
  const [excVenda, setExcVenda] = useState(
    property ? property.exc_venda || "" : ""
  );
  const [excVendaDesc, setExcVendaDesc] = useState(
    property ? property.exc_venda_desc || "" : ""
  );
  const [permutaDesc, setPermutaDesc] = useState(
    property ? property.permuta_desc || "" : ""
  );
  const [statusImovel, setStatusImovel] = useState(
    property ? property.status_imovel || "" : ""
  );
  const [tipoNavent, setTipoNavent] = useState(
    property ? property.tipo_navent || "" : ""
  );
  const [condominioID, setCondominioID] = useState(
    property ? property.condominioID || "" : ""
  );
  const [telOperad1, setTelOperad1] = useState(
    property ? property.tel_operad1 || "" : ""
  );
  const [telOperad2, setTelOperad2] = useState(
    property ? property.tel_operad2 || "" : ""
  );
  const [telOperad3, setTelOperad3] = useState(
    property ? property.tel_operad3 || "" : ""
  );

  const [files, setFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState(
    property ? property.image_urls : []
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [inputKey, setInputKey] = useState(Date.now());
  const [destaque, setDestaque] = useState(
    property ? property.destaque || "SIMPLE" : "SIMPLE"
  );

  const handleImageChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    const filePreviews = selectedFiles.map((file) => URL.createObjectURL(file));
    setImagePreviews(filePreviews);
  };

  const handleRemoveImage = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    const newPreviews = imagePreviews.filter((_, i) => i !== index);
    setImagePreviews(newPreviews);
    setInputKey(Date.now()); // Atualizar a chave para resetar o input de arquivo
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("category", category);
    formData.append("tipoPropriedade", tipoPropriedade);
    formData.append("code", code);
    formData.append("location", location);
    formData.append("price", price);
    details.split(",").forEach((detail, index) => {
      formData.append(`details[${index}]`, detail.trim());
    });
    formData.append("full_description", fullDescription);
    formData.append("reference", reference);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("dorm", dorm);
    formData.append("suite", suite);
    formData.append("suite_master", suiteMaster);
    formData.append("salao_festas", salaoFestas);
    formData.append("churrasqueira", churrasqueira);
    formData.append("quiosque", quiosque);
    formData.append("sala_jantar", salaJantar);
    formData.append("piscina", piscina);
    formData.append("sala_tv", salaTV);
    formData.append("sauna", sauna);
    formData.append("sala_estar", salaEstar);
    formData.append("campo_futebol", campoFutebol);
    formData.append("lareira", lareira);
    formData.append("quadra_esportes", quadraEsportes);
    formData.append("mezanino", mezanino);
    formData.append("quadra_tenis", quadraTenis);
    formData.append("pe_direito_alto", peDireitoAlto);
    formData.append("casa_caseiro", casaCaseiro);
    formData.append("cozinha", cozinha);
    formData.append("casa_hospedes", casaHospedes);
    formData.append("copa", copa);
    formData.append("vagas", vagas);
    formData.append("despensa", despensa);
    formData.append("canil", canil);
    formData.append("banheiros", banheiros);
    formData.append("lavabo", lavabo);
    formData.append("lago", lago);
    formData.append("area_servicos", areaServicos);
    formData.append("nascente", nascente);
    formData.append("varanda", varanda);
    formData.append("pomar", pomar);
    formData.append("quartinho_ferramentas", quartinhoFerramentas);
    formData.append("horta", horta);
    formData.append("sala_jogos", salaJogos);
    formData.append("jardim", jardim);
    formData.append("baia", baia);
    formData.append("ac", ac); // Área construída
    formData.append("at", at); // Área total
    formData.append("owner", owner); // Proprietário
    formData.append("caretaker", caretaker); // Caseiro
    formData.append("dorm_suite", dormSuite);
    formData.append("data_construcao", dataConstrucao);
    formData.append("iptu", iptu);
    formData.append("condominio", condominio);
    formData.append("video", video);
    formData.append("valor_venda", valorVenda);
    formData.append("email", email);
    formData.append("tel1", tel1);
    formData.append("tel2", tel2);
    formData.append("tel3", tel3);
    formData.append("tel_c", telC);
    formData.append("adm_imovel", admImovel);
    formData.append("chaves", chaves);
    formData.append("captacao", captacao);
    formData.append("captacao_nome", captacaoNome);
    formData.append("captacao_email", captacaoEmail);
    formData.append("captacao_telefone", captacaoTelefone);
    formData.append("docs", docs);
    formData.append("comissao", comissao);
    formData.append("comissao_loc", comissaoLoc);
    formData.append("exc_venda", excVenda);
    formData.append("exc_venda_desc", excVendaDesc);
    formData.append("permuta_desc", permutaDesc);
    formData.append("status_imovel", statusImovel);
    formData.append("tipo_navent", tipoNavent);
    formData.append("condominioID", condominioID);
    formData.append("tel_operad1", telOperad1);
    formData.append("tel_operad2", telOperad2);
    formData.append("tel_operad3", telOperad3);
    formData.append("destaque", destaque); // Adiciona o campo de destaque

    formData.append("bairroId", neighborhood);
    formData.append("cidadeId", city);
    formData.append("estadoId", state);

    formData.append("highlight", false);
    formData.append("status", property?.status != null ? property.status : "I");
    formData.append(
      "highlightImovelWeb",
      property?.highlightImovelWeb != null ? property.highlightImovelWeb : "I"
    );

    files.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const url = property
        ? `${server}/api/imoveis/${property._id}`
        : `${server}/api/imoveis`;
      const method = property ? "put" : "post";

      const response = await axiosInstance({
        method: method,
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === (property ? 200 : 201)) {
        navigate("/");

        toast.success(
          `${property ? "Atualizado" : "Criado"} com sucesso! Cód: ${code}`
        );
        toast.info("Não esqueça de subir as imagens!");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
      // Limpar tudo após o envio
      setFiles([]);
      setImagePreviews([]);
      setInputKey(Date.now()); // Resetar o input de arquivo
      if (setEditProperty) setEditProperty(null);
    } catch (error) {
      console.error("Erro ao salvar imóvel:", error);

      // Verifica se há mensagem de erro personalizada da API
      if (error.response && error.response.data && error.response.data.error) {
        const errorMessage = error.response.data.error;
        toast.error(`Erro ao salvar imóvel: ${errorMessage}`);
      } else {
        toast.error(
          "Erro ao salvar imóvel. Por favor, tente novamente mais tarde."
        );
      }
    }
  };

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const nextImage = () => {
    if (selectedImageIndex < imagePreviews.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const previousImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  const [cidades, setCidades] = useState([]);

  useEffect(() => {
    const fetchCidades = async () => {
      if (state) {
        try {
          const response = await axiosInstance.get(
            `${server}/api/imoveis/localidades/${state}`
          );
          if (Array.isArray(response.data.localidades)) {
            setCidades(response.data.localidades);
          } else {
            setCidades([]);
            console.error(
              "A resposta da API não é um array:",
              response.data.localidades
            );
          }
        } catch (error) {
          console.error("Erro ao buscar cidades", error);
          toast.error("Erro ao buscar cidades");
          setCidades([]);
        }
      }
    };

    fetchCidades();
  }, [state]);

  const [bairros, setBairros] = useState([]);

  useEffect(() => {
    const fetchBairros = async () => {
      if (city) {
        try {
          const response = await axiosInstance.get(
            `${server}/api/imoveis/localidades/${city}`
          );
          if (Array.isArray(response.data.localidades)) {
            setBairros(response.data.localidades);
          } else {
            setBairros([]);
            console.error(
              "A resposta da API não é um array:",
              response.data.localidades
            );
          }
        } catch (error) {
          console.error("Erro ao buscar cidades", error);
          toast.error("Erro ao buscar cidades");
          setBairros([]);
        }
      }
    };

    fetchBairros();
  }, [city]);

  const tiposPropriedade = [
    {
      id: "1005",
      categoria: "Comercial",
      idCategoria: "2",
      nombre: "Comercial",
    },
    {
      id: "2",
      categoria: "Residencial",
      idCategoria: "1",
      nombre: "Apartamento",
    },
    { id: "1", categoria: "Residencial", idCategoria: "1", nombre: "Casa" },
    {
      id: "1004",
      categoria: "Residencial",
      idCategoria: "1",
      nombre: "Rurais",
    },
    {
      id: "1003",
      categoria: "Residencial",
      idCategoria: "1",
      nombre: "Terreno",
    },
  ];

  return (
    <div className="container mt-5">
      <div
        className="title-text"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2>{property ? "Editar Propriedade" : "Criar nova Propriedade"}</h2>
        {property ? (
          <>
            <button
              className="btn btn-dark"
              onClick={() => window.location.reload()}
            >
              Voltar
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="form-titulo">Título</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o Título..."
              id="form-titulo"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="form-desc">Descrição</label>
            <input
              type="text"
              className="form-control"
              id="form-desc"
              placeholder="Digite a descrição..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            {" "}
            {/* Adicionado */}
            <label>Tipo de Propriedade</label>
            <select
              className="form-control"
              value={tipoPropriedade}
              onChange={(e) => setTipoPropriedade(e.target.value)}
              required
            >
              <option value="">Selecione um tipo</option>
              {tiposPropriedade.map((tipo) => (
                <option key={tipo.id} value={tipo.id}>
                  {tipo.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-6 mb-3">
            <label>Categoria</label>
            <select
              className="form-control"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Selecione uma Categoria</option>
              <option value="Áreas industriais a Venda">
                Áreas industriais a Venda
              </option>
              <option value="Chácaras a Venda">Chácaras</option>
              <option value="Condomínios a Venda">Condomínios</option>
              <option value="Fazendas a Venda">Fazendas</option>
              <option value="Haras a Venda">Haras</option>
              <option value="Represa a Venda">Represas</option>
              <option value="Sítios a Venda">Sítios</option>
              <option value="Terrenos a Venda">Terrenos</option>
            </select>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 mb-3">
            <label>Código</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o Código"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>Localização</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a localização"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label>Preço</label>
            <input
              type="text"
              min="0"
              className="form-control"
              placeholder="Digite o preço"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="mb-3">
          <label>Detalhes (separados por vírgula)</label>
          <input
            type="text"
            className="form-control"
            placeholder="Digite os detalhes"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label>Descrição Completa</label>
          <textarea
            className="form-control"
            placeholder="Digite a descrição completa"
            value={fullDescription}
            onChange={(e) => setFullDescription(e.target.value)}
          />
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Referência</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a referência"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            {" "}
            {/* Alterado */}
            <label>Estado</label>
            <select
              className="form-control"
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
            >
              <option value="">Selecione o Estado</option>
              <option value="V1-B-241">Acre</option>
              <option value="V1-B-242">Alagoas</option>
              <option value="V1-B-243">Amapá</option>
              <option value="V1-B-244">Amazonas</option>
              <option value="V1-B-245">Bahia</option>
              <option value="V1-B-246">Ceará</option>
              <option value="V1-B-247">Distrito Federal</option>
              <option value="V1-B-248">Espirito Santo</option>
              <option value="V1-B-249">Goiás</option>
              <option value="V1-B-250">Maranhão</option>
              <option value="V1-B-251">Mato Grosso</option>
              <option value="V1-B-252">Mato Grosso do Sul</option>
              <option value="V1-B-253">Minas Gerais</option>
              <option value="V1-B-256">Paraná</option>
              <option value="V1-B-255">Paraiba</option>
              <option value="V1-B-254">Pará</option>
              <option value="V1-B-257">Pernambuco</option>
              <option value="V1-B-258">Piauí</option>
              <option value="V1-B-259">Rio de Janeiro</option>
              <option value="V1-B-260">Rio Grande do Norte</option>
              <option value="V1-B-261">Rio Grande do Sul</option>
              <option value="V1-B-262">Rondônia</option>
              <option value="V1-B-263">Roraima</option>
              <option value="V1-B-264">Santa Catarina</option>
              <option value="V1-B-266">Sergipe</option>
              <option value="V1-B-265">São Paulo</option>
              <option value="V1-B-267">Tocantins</option>
              <option value="V1-B-4000">Outros Paises</option>
            </select>
          </div>
          <div className="col-md-4 mb-3">
            <label>Cidade</label>
            <select
              value={city}
              className="form-control"
              onChange={(e) => setCity(e.target.value)}
              required
            >
              <option value="">Selecione uma cidade</option>
              {Array.isArray(cidades) && cidades.length > 0 ? (
                cidades.map((cidade) => (
                  <option key={cidade.id} value={cidade.id}>
                    {cidade.nombre}
                  </option>
                ))
              ) : (
                <option value="">Nenhuma cidade encontrada</option>
              )}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Bairro</label>
            <select
              value={neighborhood}
              className="form-control"
              onChange={(e) => setNeighborhood(e.target.value)}
              required
            >
              <option value="">Selecione um bairro</option>
              {Array.isArray(bairros) && bairros.length > 0 ? (
                bairros.map((bairro) => (
                  <option key={bairro.id} value={bairro.id}>
                    {bairro.nombre}
                  </option>
                ))
              ) : (
                <option value="">Nenhuma bairro encontrado</option>
              )}
            </select>
          </div>
          <div className="col-md-4 mb-3">
            <label>Dormitórios</label>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Digite o número de dormitórios"
              value={dorm}
              onChange={(e) => setDorm(Number(e.target.value))}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Suítes</label>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Digite o número de suítes"
              value={suite}
              onChange={(e) => setSuite(Number(e.target.value))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Área Construída (m²)</label>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Digite a área construída"
              value={ac}
              onChange={(e) => setAc(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Área Total (m²)</label>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Digite a área total"
              value={at}
              onChange={(e) => setAt(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Proprietário</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o nome do proprietário"
              value={owner}
              onChange={(e) => setOwner(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Caseiro</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o nome do caseiro"
              value={caretaker}
              onChange={(e) => setCaretaker(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Vagas</label>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Digite o número de vagas"
              value={vagas}
              onChange={(e) => setVagas(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Dormitórios com Suíte</label>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Digite a quantidade de dormitórios com suíte"
              value={dormSuite}
              onChange={(e) => setDormSuite(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Data de Construção</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a data de construção"
              value={dataConstrucao}
              onChange={(e) => setDataConstrucao(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>IPTU</label>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Digite o valor do IPTU"
              value={iptu}
              onChange={(e) => setIptu(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Condomínio</label>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Digite o valor do condomínio"
              value={condominio}
              onChange={(e) => setCondominio(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Vídeo</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o link do vídeo"
              value={video}
              onChange={(e) => setVideo(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Valor de Venda</label>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Digite o valor de venda"
              value={valorVenda}
              onChange={(e) => setValorVenda(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Digite o email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Telefone 1</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o telefone 1"
              value={tel1}
              onChange={(e) => setTel1(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Telefone 2</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o telefone 2"
              value={tel2}
              onChange={(e) => setTel2(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Telefone 3</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o telefone 3"
              value={tel3}
              onChange={(e) => setTel3(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Telefone do Caseiro</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o telefone do caseiro"
              value={telC}
              onChange={(e) => setTelC(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Administração do Imóvel</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a administração do imóvel"
              value={admImovel}
              onChange={(e) => setAdmImovel(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Chaves</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a informação sobre chaves"
              value={chaves}
              onChange={(e) => setChaves(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Captação</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a captação"
              value={captacao}
              onChange={(e) => setCaptacao(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Nome da Captação</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o nome da captação"
              value={captacaoNome}
              onChange={(e) => setCaptacaoNome(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Email da Captação</label>
            <input
              type="email"
              className="form-control"
              placeholder="Digite o email da captação"
              value={captacaoEmail}
              onChange={(e) => setCaptacaoEmail(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Telefone da Captação</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o telefone da captação"
              value={captacaoTelefone}
              onChange={(e) => setCaptacaoTelefone(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Documentos</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a informação sobre documentos"
              value={docs}
              onChange={(e) => setDocs(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Comissão</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a comissão"
              value={comissao}
              onChange={(e) => setComissao(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Comissão de Locação</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a comissão de locação"
              value={comissaoLoc}
              onChange={(e) => setComissaoLoc(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Exclusividade de Venda</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a exclusividade de venda"
              value={excVenda}
              onChange={(e) => setExcVenda(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Descrição da Exclusividade de Venda</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a descrição da exclusividade de venda"
              value={excVendaDesc}
              onChange={(e) => setExcVendaDesc(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Descrição da Permuta</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a descrição da permuta"
              value={permutaDesc}
              onChange={(e) => setPermutaDesc(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Status do Imóvel</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o status do imóvel"
              value={statusImovel}
              onChange={(e) => setStatusImovel(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Tipo de Navegação</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o tipo de navegação"
              value={tipoNavent}
              onChange={(e) => setTipoNavent(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>ID do Condomínio</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite o ID do condomínio"
              value={condominioID}
              onChange={(e) => setCondominioID(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Operadora do Telefone 1</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a operadora do telefone 1"
              value={telOperad1}
              onChange={(e) => setTelOperad1(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Operadora do Telefone 2</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a operadora do telefone 2"
              value={telOperad2}
              onChange={(e) => setTelOperad2(e.target.value)}
            />
          </div>
          <div className="col-md-4 mb-3">
            <label>Operadora do Telefone 3</label>
            <input
              type="text"
              className="form-control"
              placeholder="Digite a operadora do telefone 3"
              value={telOperad3}
              onChange={(e) => setTelOperad3(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Destaque</label>
            <select
              className="form-control"
              value={destaque}
              onChange={(e) => setDestaque(e.target.value)}
            >
              <option value="SIMPLE">Simples</option>
              <option value="DESTACADO">Destaque</option>
              <option value="HOME">Super Destaque</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="suiteMaster"
              checked={suiteMaster}
              onChange={(e) => setSuiteMaster(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="suiteMaster">
              Suíte Master
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="salaoFestas"
              checked={salaoFestas}
              onChange={(e) => setSalaoFestas(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="salaoFestas">
              Salão de Festas
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="churrasqueira"
              checked={churrasqueira}
              onChange={(e) => setChurrasqueira(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="churrasqueira">
              Churrasqueira
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="quiosque"
              checked={quiosque}
              onChange={(e) => setQuiosque(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="quiosque">
              Quiosque
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="salaJantar"
              checked={salaJantar}
              onChange={(e) => setSalaJantar(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="salaJantar">
              Sala de Jantar
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="piscina"
              checked={piscina}
              onChange={(e) => setPiscina(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="piscina">
              Piscina
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="salaTV"
              checked={salaTV}
              onChange={(e) => setSalaTV(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="salaTV">
              Sala de TV
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="sauna"
              checked={sauna}
              onChange={(e) => setSauna(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="sauna">
              Sauna
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="salaEstar"
              checked={salaEstar}
              onChange={(e) => setSalaEstar(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="salaEstar">
              Sala de Estar
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="campoFutebol"
              checked={campoFutebol}
              onChange={(e) => setCampoFutebol(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="campoFutebol">
              Campo de Futebol
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="lareira"
              checked={lareira}
              onChange={(e) => setLareira(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="lareira">
              Lareira
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="quadraEsportes"
              checked={quadraEsportes}
              onChange={(e) => setQuadraEsportes(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="quadraEsportes">
              Quadra de Esportes
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="mezanino"
              checked={mezanino}
              onChange={(e) => setMezanino(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="mezanino">
              Mezanino
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="quadraTenis"
              checked={quadraTenis}
              onChange={(e) => setQuadraTenis(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="quadraTenis">
              Quadra de Tênis
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="peDireitoAlto"
              checked={peDireitoAlto}
              onChange={(e) => setPeDireitoAlto(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="peDireitoAlto">
              Pé Direito Alto
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="casaCaseiro"
              checked={casaCaseiro}
              onChange={(e) => setCasaCaseiro(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="casaCaseiro">
              Casa do Caseiro
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="cozinha"
              checked={cozinha}
              onChange={(e) => setCozinha(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="cozinha">
              Cozinha
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="casaHospedes"
              checked={casaHospedes}
              onChange={(e) => setCasaHospedes(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="casaHospedes">
              Casa de Hóspedes
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="copa"
              checked={copa}
              onChange={(e) => setCopa(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="copa">
              Copa
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="despensa"
              checked={despensa}
              onChange={(e) => setDespensa(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="despensa">
              Despensa
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="canil"
              checked={canil}
              onChange={(e) => setCanil(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="canil">
              Canil
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label>Banheiros</label>
            <input
              type="number"
              className="form-control"
              placeholder="Digite o número de banheiros"
              value={banheiros}
              onChange={(e) => setBanheiros(Number(e.target.value))}
            />
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="lavabo"
              checked={lavabo}
              onChange={(e) => setLavabo(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="lavabo">
              Lavabo
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="lago"
              checked={lago}
              onChange={(e) => setLago(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="lago">
              Lago
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="areaServicos"
              checked={areaServicos}
              onChange={(e) => setAreaServicos(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="areaServicos">
              Área de Serviços
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="nascente"
              checked={nascente}
              onChange={(e) => setNascente(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="nascente">
              Nascente
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="varanda"
              checked={varanda}
              onChange={(e) => setVaranda(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="varanda">
              Varanda
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="pomar"
              checked={pomar}
              onChange={(e) => setPomar(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="pomar">
              Pomar
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="quartinhoFerramentas"
              checked={quartinhoFerramentas}
              onChange={(e) => setQuartinhoFerramentas(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="quartinhoFerramentas">
              Quartinho de Ferramentas
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="horta"
              checked={horta}
              onChange={(e) => setHorta(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="horta">
              Horta
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="salaJogos"
              checked={salaJogos}
              onChange={(e) => setSalaJogos(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="salaJogos">
              Sala de Jogos
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="jardim"
              checked={jardim}
              onChange={(e) => setJardim(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="jardim">
              Jardim
            </label>
          </div>
          <div className="col-md-4 mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="baia"
              checked={baia}
              onChange={(e) => setBaia(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="baia">
              Baia
            </label>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          {property ? "Atualizar Propriedade" : "Criar Propriedade"}
        </button>
      </form>
    </div>
  );
}

export default PropertyForm;
