import React, { useState, useEffect } from "react";
import axiosInstance from "../source/axiosInstance";
import PropertyList from "../components/PropertyList/PropertyList";
import PropertyForm from "../components/PropertyForm/PropertyForm";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../util/server";

function Home() {
  const [properties, setProperties] = useState([]);
  const [propertiesSearch, setPropertiesSearch] = useState([]);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editProperty, setEditProperty] = useState(null);
  const [status, setStatus] = useState(""); // Novo estado para status
  const [highlight, setHighlight] = useState(""); // Novo estado para destaque

  useEffect(() => {
    fetchProperties();
  }, [currentPage, itemsPerPage, status, highlight]);

  useEffect(() => {
    applyFilters();
  }, [search, category]);

  const fetchProperties = async () => {
    try {
      const response = await axiosInstance.get(
        `${server}/api/imoveis/painel/p/imoveis`,
        {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            status: status,
            highlight: highlight,
          },
        }
      );
      setProperties(response.data.data);

      const response2 = await axiosInstance.get(
        `${server}/api/imoveis/painel/p/imoveis`,
        {
          params: {
            page: 1,
            limit: 10000,
            status: status,
            highlight: highlight,
          },
        }
      );
      setPropertiesSearch(response2.data.data);

      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDestaqueChange = async (property, novoDestaque) => {
    if (!window.confirm("Tem certeza que deseja alterar o tipo de destaque?")) {
      return;
    }

    try {
      const response = await axiosInstance.put(
        `${server}/api/imoveis/${property._id}/destaque`,
        {
          destaque: novoDestaque,
        }
      );

      if (response.status === 200) {
        const updatedProperties = properties.map((p) =>
          p._id === property._id ? { ...p, destaque: novoDestaque } : p
        );
        toast.success("Tipo de destaque atualizado com sucesso.");
        fetchProperties();
      }
    } catch (error) {
      toast.error(
        "Erro ao atualizar tipo de destaque. Por favor, tente novamente."
      );
      console.error("Erro ao atualizar tipo de destaque:", error);
    } finally {
    }
  };

  const applyFilters = () => {
    const sanitizedTerm = DOMPurify.sanitize(search);
    const regex = new RegExp(sanitizedTerm, "i");
    const filtered = propertiesSearch.filter((property) => {
      const matchesSearch =
        regex.test(property.code) ||
        regex.test(property.title) ||
        regex.test(property.description) ||
        regex.test(property.full_description) ||
        regex.test(property.category) ||
        regex.test(property.location) ||
        regex.test(property.price) ||
        property.details.some((detail) => regex.test(detail));

      const matchesFilters = !category || property.category === category;

      return matchesSearch && matchesFilters;
    });
    setProperties(
      filtered.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    );
    setTotalPages(Math.ceil(filtered.length / itemsPerPage));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEdit = (property) => {
    setEditProperty(property);
  };

  const toggleHighlight = async (property) => {
    try {
      toast.info("Aguarde um pouco...");
      const response = await axiosInstance.put(
        `${server}/api/imoveis/${property._id}/toggle-highlight`
      );
      property.highlight = response.data.highlight;
      fetchProperties();
    } catch (error) {
      console.error("Error toggling highlight:", error);
    }
  };

  const toggleHighlightImovelWeb = async (property) => {
    try {
      toast.info("Aguarde um pouco...");
      const response = await axiosInstance.put(
        `${server}/api/imoveis/${property._id}/toggle-highlight-imovelweb`
      );
      property.highlight = response.data.highlight;
      fetchProperties();
    } catch (error) {
      console.error("Error toggling highlight:", error);
    }
  };

  const toggleStatus = async (property) => {
    try {
      toast.info("Aguarde um pouco...");
      const response = await axiosInstance.put(
        `${server}/api/imoveis/${property._id}/toggle-status`
      );
      property.status = response.data.status;
      fetchProperties();
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  const downloadImages = async (property) => {
    try {
      toast.info("Aguarde um pouco...");
      const response = await axiosInstance.get(
        `${server}/api/imoveis/${property._id}/download-images`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${property.code}_images.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading images:", error);
    }
  };

  const navigate = useNavigate();

  return (
    <div className="container mt-5">
      {editProperty ? (
        <PropertyForm
          property={editProperty}
          setEditProperty={setEditProperty}
        />
      ) : (
        <>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className="tittle-container"
          >
            <h2>Lista de Propriedades</h2>
            <button
              className="btn btn-success"
              onClick={() => navigate("/new-property")}
            >
              Criar Propriedade
            </button>
          </div>
          <br />
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Buscar propriedades"
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div className="mb-3">
            <select
              className="form-control"
              value={category}
              onChange={handleCategoryChange}
            >
              <option value="">Todos</option>
              <option value="Áreas industriais a Venda">
                Áreas industriais
              </option>
              <option value="Chácaras a Venda">Chácaras</option>
              <option value="Condomínios a Venda">Condomínios</option>
              <option value="Fazendas a Venda">Fazendas</option>
              <option value="Haras a Venda">Haras</option>
              <option value="Represa a Venda">Represas</option>
              <option value="Sítios a Venda">Sítios</option>
              <option value="Terrenos a Venda">Terrenos</option>
            </select>
          </div>
          <div className="mb-3">
            <select
              className="form-control"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value="">Todos os Status</option>
              <option value="A">Ativos</option>
              <option value="I">Inativos</option>
            </select>
          </div>
          <div className="mb-3">
            <select
              className="form-control"
              value={highlight}
              onChange={(e) => {
                setHighlight(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value="">Todos os Destaques</option>
              <option value="true">Destacados</option>
              <option value="false">Não Destacados</option>
            </select>
          </div>
          <div className="mb-3">
            <select
              className="form-control"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={75}>75</option>
              <option value={100}>100</option>
            </select>
          </div>
          <PropertyList
            properties={properties}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onEdit={handleEdit}
            downloadImages={downloadImages}
            toggleHighlight={toggleHighlight}
            toggleStatus={toggleStatus}
            toggleHighlightImovelWeb={toggleHighlightImovelWeb}
            handleDestaqueChange={handleDestaqueChange}
          />
        </>
      )}
    </div>
  );
}

export default Home;
