import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faUpload } from "@fortawesome/free-solid-svg-icons";
import { server } from "../../util/server";
import { toast } from "react-toastify";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import axiosInstance from "../../source/axiosInstance";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const SortableImage = SortableElement(({ image, onDelete }) => (
  <div className="col-md-3">
    <div className="card mb-3">
      <img
        src={
          image.startsWith("blob:")
            ? image
            : `${server}/api/imoveis/image/${image}`
        }
        className="card-img-top"
        alt="Imagem da Propriedade"
      />
      <div className="card-body text-center">
        <button className="btn btn-danger" onClick={() => onDelete(image)}>
          <FontAwesomeIcon icon={faTimes} /> Excluir
        </button>
      </div>
    </div>
  </div>
));

const SortableImageList = SortableContainer(({ images, onDelete }) => {
  return (
    <div className="row">
      {images.map((image, index) => (
        <SortableImage
          key={`item-${index}`}
          index={index}
          image={image}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
});

const UploadImages = () => {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imovel, setImovel] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axiosInstance.get(`${server}/api/imoveis/${id}`);
      setImovel(response.data);
      setImages(response.data.image_urls);
    } catch (error) {
      console.error("Erro ao buscar imagens:", error);
    }
  };

  const handleDeleteImage = async (imageName) => {
    try {
      const response = await axiosInstance.delete(
        `${server}/api/imoveis/${id}/images/${encodeURIComponent(imageName)}`
      );
      if (response.status === 204) {
        toast.success("Imagem deletada com sucesso!");
        fetchImages();
      }
    } catch (error) {
      toast.error("Erro ao deletar imagem. Por favor, tente novamente.");
      console.error("Erro ao deletar imagem:", error);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);

    const previews = files.map((file) => URL.createObjectURL(file));
    setImages((prevImages) => [...previews, ...prevImages]);

    uploadFilesOneByOne(files);
  };

  const uploadFilesOneByOne = async (files) => {
    for (const file of files) {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("images", file);

    try {
      toast.info(`Iniciando upload de ${file.name}...`);
      const response = await axiosInstance.post(
        `${server}/api/imoveis/${id}/images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: progress,
            }));
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toast.success(`Imagem ${file.name} adicionada com sucesso!`);
        fetchImages(); // Refresh images after upload
      } else {
        toast.error(`Erro ao adicionar imagem ${file.name}!`);
      }
    } catch (error) {
      toast.error(`Erro ao fazer upload da imagem ${file.name}!`);
      console.error(`Erro ao fazer upload da imagem ${file.name}:`, error);
    } finally {
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [file.name]: null,
      }));
      setSelectedFiles((prevFiles) =>
        prevFiles.filter((f) => f.name !== file.name)
      );
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newImages = arrayMoveImmutable(images, oldIndex, newIndex);
    setImages(newImages);
    saveImageOrder(newImages);
  };

  const saveImageOrder = async (newOrder) => {
    try {
      await axiosInstance.post(`${server}/api/imoveis/${id}/images/order`, {
        image_urls: newOrder,
      });
      toast.success("Ordem das imagens salva com sucesso!");
    } catch (error) {
      toast.error("Erro ao salvar a ordem das imagens!");
      console.error("Erro ao salvar a ordem das imagens:", error);
    }
  };

  const handleDeleteAllImages = () => {
    confirmAlert({
      title: "Confirmar Deleção",
      message: "Você tem certeza que deseja deletar todas as imagens?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              const response = await axiosInstance.delete(
                `${server}/api/imoveis/${id}/images`
              );
              if (response.status === 204) {
                toast.success("Todas as imagens foram deletadas com sucesso!");
                fetchImages();
              }
            } catch (error) {
              toast.error("Erro ao deletar todas as imagens!");
              console.error("Erro ao deletar todas as imagens:", error);
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div className="container mt-5">
      <div
        className="title-text"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h2>Gerenciar Imagens para a Propriedade - Cód. {imovel.code}</h2>
        {imovel ? (
          <>
            <button
              className="btn btn-dark"
              onClick={() => (window.location.href = "/")}
            >
              Voltar
            </button>
          </>
        ) : (
          ""
        )}
      </div>
      <br />
      <div className="mb-3 align-content-between flex-row justify-content-between">
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple
        />
        <button
          className="btn btn-primary"
          onClick={() => document.getElementById("fileInput").click()}
        >
          <FontAwesomeIcon icon={faUpload} /> Carregar Imagens
        </button>

        <button
          style={{ marginLeft: "auto" }}
          className="btn btn-danger"
          onClick={handleDeleteAllImages}
        >
          <FontAwesomeIcon icon={faUpload} /> Deletar todas as Imagens
        </button>
      </div>

      <div className="row">
        {selectedFiles.map((file, index) => (
          <div className="col-md-3" key={index}>
            <div className="card mb-3">
              <img
                src={URL.createObjectURL(file)}
                className="card-img-top"
                alt="Pré-visualização da Imagem"
              />
              <div className="card-body text-center">
                <div className="progress mb-2">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${uploadProgress[file.name] || 0}%` }}
                    aria-valuenow={uploadProgress[file.name] || 0}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {uploadProgress[file.name] || 0}%
                  </div>
                </div>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteImage(file.name)}
                >
                  <FontAwesomeIcon icon={faTimes} /> Excluir
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <SortableImageList
        images={images.filter((img) => !img.startsWith("blob:"))}
        onDelete={handleDeleteImage}
        onSortEnd={onSortEnd}
        axis="xy"
      />
    </div>
  );
};

export default UploadImages;