import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Login from "./components/Login/Login";
import Home from "./pages/Home";
import PropertyForm from "./components/PropertyForm/PropertyForm";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import UploadImages from "./components/uploadImages/UploadImages";
import axiosInstance from "./source/axiosInstance";
import ManagerUsuarios from "./manager/ManagerUsuarios/ManagerUsuarios";
import "./App.css";
import Dashboard from "./components/Dashboard/Dashboard";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const handleLogin = (status) => {
    setIsAuthenticated(status);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    setIsAuthenticated(false);
    window.location.href = "/login";
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
        </Routes>
        <div className="d-flex">
          {isAuthenticated && <Sidebar />}
          <div className="flex-grow-1 main-content">
            <ToastContainer />
            <Routes>
              <Route
                path="/new-property"
                element={
                  isAuthenticated ? <PropertyForm /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/upload-images/:id"
                element={
                  isAuthenticated ? <UploadImages /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/"
                element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
              />
              <Route
                path="/dashboard"
                element={
                  isAuthenticated ? (
                    <h1>Em manutenção!!</h1>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
                /* <Dashboard /> */
              />
              <Route
                path="/cidades"
                element={
                  isAuthenticated ? (
                    <h1>Em manutenção!!</h1>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/categorias"
                element={
                  isAuthenticated ? (
                    <h1>Em manutenção!!</h1>
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              <Route
                path="/usuarios"
                element={
                  isAuthenticated ? (
                    <ManagerUsuarios />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />
              {/* <Route path="*" element={<h1>404</h1>} /> */}
            </Routes>
          </div>
        </div>
      </Router>
    </>
  );
}

export default App;
