import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../util/server";
import "./Login.css";

function Login({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${server}/api/user/login`, {
        username,
        password,
      });
      const { token, imovelWebToken } = response.data;
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("imovelWebToken", imovelWebToken);

      onLogin(true);
      navigate("/");
    } catch (error) {
      toast.error("Senha Incorreta");
    }
  };

  return (
    <div className="login-container">
      <div
        className="container mt-5"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <div className="text-center mb-4">
                  <img
                    style={{ width: "140px" }}
                    alt="Logo"
                    src="/logo-not.png"
                  />
                </div>
                <h2 className="text-center mb-4">Entrar</h2>
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Usuário
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="Digite seu acesso..."
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Senha
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Digite sua senha..."
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="d-grid">
                    <button className="btn btn-primary btn-block" type="submit">
                      Entrar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;